.fullWidth {
  width: 100% !important;
}

.fullHeight {
  height: 100% !important;
}

.justifyContent {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0px;
}

/* global styles */
.MuiDataGrid-row {
  cursor: pointer;
}

.MuiDataGrid-sortIcon {
  color: black;
  /* color:green !important; */
  /* font-size: 28px !important; */
}

.Mui-expanded {
  margin-bottom: 10px;
}

.MuiDataGrid-root .MuiDataGrid-cellCheckbox {
  padding-left: 20px !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox {
  padding-left: 14px !important;
}

.pagination {
  position: fixed !important;
  bottom: 0;
  margin: 1.5rem auto;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 30rem;
  /* z-index: 3; */
  background-color: whitesmoke;

  padding: 0;
}

.g-pagination {
  position: absolute !important;
  bottom: 0;
  left: 0 !important;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  width: 100%;
  background-color: white !important;
  padding-left: 0;
}

.topBar {
  background-color: #009cdf !important;
  color: white !important;
  display: flex !important;
}

.topBarText {
  display: none;
}

@media only screen and (min-width: 800px) {
  .topBarText {
    display: inline !important;
    color: white !important;
  }

  /* z-index: 3; */
  /* background-color: white;
  margin: 0;
  padding: 0; */
}

.active-li {
  /* background-color: #1a5ba9; */
  border: 1px solid gray;
  color: white;

  border-radius: 4px;
}

.active-link {
  color: white;
}

.break-me {
  margin: 0.5rem;

  padding: 0.5rem 1rem;

  cursor: pointer;
  color: gray;
}

.break-me-a {
  color: rgba(0, 0, 0, 0.434);
  font-weight: 600;
}

.next-li {
  background-color: white !important;
}

.next-li,
.prev-li {
  border: 1px solid gray;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  margin: 0;
  padding: 12px;
  font-size: 22px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.prev-link,
.next-link {
  color: gray;
}

.listItemText {
  color: white !important;
  font-size: 10px !important;
}

/* .MuiListItemText-primary {
    border: 0px solid black !important;
} */

.listItemIcon {
  color: white !important;
  font-size: 1.5rem !important;
}

.closeButton {
  color: white !important;
}

.whiteColor {
  color: white !important;
}

.avatar {
  position: absolute !important;
  right: 2% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.userName {
  margin-right: 10px !important;
  margin-left: 20px !important;
  color: white !important;
}

.userMenuGrid {
  width: 100%;
}

.userMenuGridItem {
  height: 50px !important;
}

.dKCjPj {
  overflow: hidden !important;
}
/* overide mui */
.css-ypofq1-MuiDataGrid-toolbarContainer {
  padding: 0.5rem;
  height: 4rem;
  background-color: whitesmoke;
}
.css-okt5j6-MuiDataGrid-columnHeaders,
.css-cwjk51-MuiDataGrid-pinnedColumnHeaders,
.css-1v5yx8h-MuiDataGrid-pinnedColumnHeaders {
  padding: 0.5rem;

  background-color: whitesmoke !important;
}
