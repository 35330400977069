.mainRowLogin {
    width: 100% !important;
    height: 100% !important;
    background-image: linear-gradient(#009cdf, #2f287f);
    margin: 0px !important;
    padding: 0px !important;
}

.mainLoginPaper {
    width: 40%;
    min-width: 300px;
    min-height: 250px;
}

.loginLogo {
    width: 50%;
    min-width: 150px;
    height: 50%;
    min-height: 150px;
}

.logoGrid {
    height: 30% !important;
}

.loginDataGrid {
    height: 70% !important;
}

.headerRow {
    margin-top: 10px !important;
    margin-left: 15% !important;
    margin-right: 15% !important;
}

.lastRow {
    margin-bottom: 30px !important;
    margin-left: 15% !important;
    margin-right: 15% !important;
}


.signInSpan {
    font-size: 2rem;
    color: rgb(47, 40, 127);
}

.subtextSpan {
    font-size: 1rem;
    display: block;
}

.loginInputField {
    width: 100%;
    border: 1px solid;
    border-radius: 3px;
    height: 2rem;
}

input::placeholder {
    font-size: 1rem;
}

.password {
    margin-top: 10px !important;
}

.failed {
    color: red;
}

.loginButton {
    width: 100%;
    height: 30px;
    background-color: #009cdf;
    border-radius: 4px;
    border: 0px;
    color: white;
    font-size: 1rem;
    font-weight: bold;
}